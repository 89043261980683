import PropTypes from 'prop-types'
import React from 'react'

import RaffleListPage from '../components/RaffleListPage'

const HealthPage = ({ location }) => (
  <RaffleListPage
    industry={location.state ? location.state.industry : 'health'}
  />
)

HealthPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default HealthPage
